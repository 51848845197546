import React from 'react';

const GearSVG = (props) => {
  const viewBox = '0 0 ' + props.width + ' ' + props.height;

  return (
    <div className={props.className}>
      <svg
        width={props.width}
        height={props.height}
        viewBox={viewBox}
        fill="fill"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M201.103 41.0376C196.821 41.0376 192.578 41.2189 188.374 41.5455C185.865 41.7405 183.564 40.1024 182.994 37.6513L175.217 4.16577C174.566 1.36331 171.69 -0.313004 168.93 0.501655L113.312 16.9223C110.774 17.6716 109.26 20.2689 109.857 22.8469L118.077 58.3039C118.586 60.4966 117.563 62.7523 115.606 63.8651C101.278 72.0147 87.9113 82.189 75.8219 94.1464C73.9291 96.0185 70.908 96.1559 68.8999 94.408L44.4358 73.1139C42.1802 71.1506 38.7171 71.593 37.0276 74.0603L1.51639 125.92C0.0820606 128.014 0.44508 130.851 2.36061 132.517L28.8875 155.589C30.555 157.039 31.0673 159.41 30.1889 161.438C23.6283 176.582 18.6882 192.566 15.4731 209.046C14.8804 212.083 17.2353 214.868 20.3302 214.868L381.841 214.868C384.936 214.868 387.29 212.085 386.699 209.048C383.481 192.53 378.525 176.512 371.938 161.34C371.046 159.286 371.583 156.882 373.294 155.437L396.594 135.769C398.535 134.131 398.939 131.297 397.533 129.181L362.668 76.722C361.014 74.2334 357.561 73.7415 355.278 75.6693L333.132 94.3706C331.123 96.0663 328.15 95.9127 326.277 94.0685C317.669 85.5917 308.41 78.0094 298.613 71.4127C296.727 70.1428 295.882 67.7831 296.588 65.6219L307.067 33.5507C307.878 31.0683 306.642 28.3799 304.23 27.3793L250.215 4.97358C247.523 3.85694 244.45 5.27002 243.546 8.04058L233.21 39.708C232.432 42.0905 230.012 43.5211 227.536 43.1284C218.78 41.739 209.948 41.0401 201.103 41.0376Z"
          fill={props.fill}
        />
      </svg>
    </div>
  );
};

export default GearSVG;
